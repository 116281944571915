<template>
  <div class="merlends upgrade MERCH">
    <!-- tag标签 -->
    <div class="merch-tag">
      <el-tabs v-model="activeName" @tab-click="handleClick(activeName)">
        <el-tab-pane label="当前说明" name="1"></el-tab-pane>
        <el-tab-pane label="历史版本" name="2"></el-tab-pane>
      </el-tabs>
    </div>
    <div v-if="activeName == '1'" style="height: 70vh">
      <div class="form-explain">
        <el-form
          label-width="200px"
          ref="upLoadForm"
          :model="upLoadForm"
          :rules="rules"
        >
          <el-form-item label="版本号：" prop="version">
            <div class="from-input">
              <el-input
                v-model="upLoadForm.version"
                placeholder="请输入发布版本号"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="下载链接：">
            <div class="from-input">
              <el-input
                v-model="upLoadForm.url"
                placeholder="请输入下载链接"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="应用端：">
            <el-radio-group v-model="upLoadForm.appType">
              <el-radio :label="2">用户端</el-radio>
              <el-radio :label="1">助手端</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="发布平台：">
            <el-checkbox-group v-model="upLoadForm.platform">
              <el-checkbox label="1">Android</el-checkbox>
              <el-checkbox label="2">IOS</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="是否强制更新：">
            <el-radio-group v-model="upLoadForm.forceUpdate">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="说明1：">
            <div class="from-input">
              <el-input
                v-model="upLoadForm.content1"
                placeholder="请输入升级提示文字1-20字"
                maxlength="20"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="说明2：">
            <div class="from-input">
              <el-input
                v-model="upLoadForm.content2"
                placeholder="请输入升级提示文字1-20字"
                maxlength="20"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="说明3：">
            <div class="from-input">
              <el-input
                v-model="upLoadForm.content3"
                placeholder="请输入升级提示文字1-20字"
                maxlength="20"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="说明4：">
            <div class="from-input">
              <el-input
                v-model="upLoadForm.content4"
                placeholder="请输入升级提示文字1-20字"
                maxlength="20"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="说明5：">
            <div class="from-input">
              <el-input
                v-model="upLoadForm.content5"
                placeholder="请输入升级提示文字1-20字"
                maxlength="20"
                clearable
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="compile-BTN">
        <div class="save-btn" @click="conserve">保存</div>
      </div>
    </div>
    <div v-if="activeName == '2'">
      <div class="table-history">
        <el-table
          border
          :data="searchData"
          style="width: 100%"
          v-loading="loading"
          :header-cell-style="{ background: '#F2F3F5' }"
        >
          <el-table-column
            align="center"
            label="版本号"
            prop="version"
          ></el-table-column>
          <el-table-column
            align="center"
            label="是否强制更新"
            prop="forceUpdate"
          >
            <template slot-scope="{ row }">
              <div>{{ row.forceUpdate == 0 ? "否" : "是" }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="应用端" prop="appType">
            <template slot-scope="{ row }">
              <div>{{ row.appType == 1 ? "助手端" : "应用端" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="发布平台"
            prop="platformStr"
          ></el-table-column>
          <el-table-column
            align="center"
            label="下载链接"
            prop="url"
          ></el-table-column>
          <el-table-column
            align="center"
            label="发布时间"
            prop="createTime"
          ></el-table-column>
          <el-table-column align="center" label="说明">
            <template slot-scope="{ row }">
              <div v-if="row.content[0]">1.{{ row.content[0] }}</div>
              <div v-if="row.content[1]">2.{{ row.content[1] }}</div>
              <div v-if="row.content[2]">3.{{ row.content[2] }}</div>
              <div v-if="row.content[3]">4.{{ row.content[3] }}</div>
              <div v-if="row.content[4]">5.{{ row.content[4] }}</div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="merch-page">
          <el-pagination
            :current-page="queryParams.page"
            background
            @current-change="handleCurrentChange"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("power"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      activeName: "1",
      searchData: [],
      loading: false,
      upLoadForm: {
        version: "",
        url: "",
        forceUpdate: 1,
        appType: 2,
        platform: ["1", "2"],
        content1: "",
        content2: "",
        content3: "",
        content4: "",
        content5: "",
      },
      isCreat: false,
      queryParams: {
        page: 1,
        perPage: 10,
      },
      total: 0,
      rules: {
        version: [{ required: true, message: "版本号必填", trigger: "change" }],
      },
    };
  },
  methods: {
    ...mapActions(["appUpgradeCreate", "recordList"]),
    handleClick(activeName) {
      if (activeName == 1) {
        // this.upLoadForm = {
        //     version: "",
        //     platform: [],
        //     content1: "",
        //     content2: "",
        //     content3: "",
        //     content4: "",
        //     content5: "",
        // }
        Object.assign(this.upLoadForm, this.$options.data().upLoadForm);
        if (this.$refs.upLoadForm !== undefined)
          this.$refs.upLoadForm.resetFields();
      } else {
        this.getList();
      }
    },
    async getList() {
      this.loading = true;
      let { data } = await this.recordList(this.queryParams);
      this.loading = false;
      this.total = data.total;
      data.data.forEach((item, index) => {
        item.content = [];
        if (item.content1 != "") {
          item.content.push(item.content1);
        }
        if (item.content2 != "") {
          item.content.push(item.content2);
        }
        if (item.content3 != "") {
          item.content.push(item.content3);
        }
        if (item.content4 != "") {
          item.content.push(item.content4);
        }
        if (item.content5 != "") {
          item.content.push(item.content5);
        }
      });
      this.searchData = data.data;
    },
    handleCurrentChange(e) {
      this.queryParams.page = e;
      this.getList();
    },
    async conserve() {
      this.$refs.upLoadForm.validate(async (valid) => {
        if (valid) {
          if (this.isCreat) {
            return;
          }
          this.isCreat = true;
          let res = await this.appUpgradeCreate(this.upLoadForm);
          this.isCreat = false;
          if (res.code == 200) {
            this.$message({
              message: res.message,
              type: "success",
            });
            Object.assign(this.upLoadForm, this.$options.data().upLoadForm);
            if (this.$refs.upLoadForm !== undefined)
              this.$refs.upLoadForm.resetFields();
          } else {
            this.$message({
              message: res.message || "服务器响应失败!!请联系管理员",
              type: "error",
              duration: 5 * 1000,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.upgrade {
  width: 100%;
  padding: 0px 20px;
  background: #ffffff;
  .form-explain {
    display: flex;
    justify-content: center;

    .from-input {
      width: 300px;
    }
  }
  .merch-page {
    margin-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: right;
  }
  .compile-BTN {
    width: 100%;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    line-height: 40px;
    font-size: 14px;
    .save-btn {
      cursor: pointer;
      width: 100px;
      height: 40px;
      text-align: center;
      border-radius: 5px;
      background: #06b7ae;
      color: #fff;
    }
  }
}
::v-deep .el-tabs__nav {
  width: 100%;
}
::v-deep .el-tabs__item {
  width: 16.59%;
  text-align: center;
}
::v-deep .el-input__inner {
  border-radius: 4px !important;
}
</style>
